import { ButtonHTMLAttributes } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../general"
import { LoadingCircular } from "../feedback"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  submitting?: boolean
}

const LoadingButton = ({ submitting, ...attrs }: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      {...attrs}
      icon={submitting && <LoadingCircular size={18} />}
      disabled={attrs.disabled || submitting}
      type={"button"}
      color="primary"
      block
    >
      {attrs.children || t("Submit")}
    </Button>
  )
}

export default LoadingButton
