import { Page } from "components/layout"
import { useBankBalance } from "data/queries/bank"
import { getAmount } from "utils/coin"
import { useGasPrices } from "data/Terra/TerraAPI"
import ClaimForm from "./ClaimForm"

const Claim = () => {
  const bankBalance = useBankBalance()

  const { data: gasPrices } = useGasPrices()

  // If the gas prices doesn't exist, nothing is worth rendering.
  if (!gasPrices) return null

  return (
    <Page>
      <ClaimForm
        decimals={6}
        balance={getAmount(bankBalance, "uluna")}
        token={"uluna"}
        symbol={"LUNC"}
        gas={gasPrices}
      />
    </Page>
  )
}

export default Claim
