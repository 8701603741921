import { useLCDClient } from "./lcdClient"
import { CONTRACT_ADDR } from "config/constants"

export const useDisputes = () => {
  const lcd = useLCDClient()

  type Dispute = {
    0: {
      key: number
    }
    1: {
      owner: string
      id: number
      game: number
      category: string
      player_a: string
      player_b: string
      player_c: string
      amount_a: number
      amount_b: number
      amount_c: number
      prob: number
      winner: string
    }
  }

  type MyDispute = {
    0: {
      key: number
    }
    1: {
      owner: string
      dispute: number
      game: number
      date_time: string
      option: number
      side_a: string
      side_b: string
      side_c: string
      amount: number
      winner: string
      open: boolean
      code: string
    }
  }

  type OneDispute = {
    owner: string
    id: number
    game: number
    category: string
    player_a: string
    player_b: string
    player_c: string
    amount_a: number
    amount_b: number
    amount_c: number
    prob: number
    winner: string
    won: boolean
  }

  type ResponseDispute = Dispute[]
  type ResponseMyDispute = MyDispute[]

  const queryListDisputes = (game: number): Promise<ResponseDispute> => {
    const contractAddress = getContractAddress()

    return lcd.wasm.contractQuery(contractAddress, {
      list_disputes: { game: game },
    })
  }

  const queryMyDisputes = (owner: String): Promise<ResponseMyDispute> => {
    const contractAddress = getContractAddress()

    return lcd.wasm.contractQuery(contractAddress, {
      my_disputes: { owner: owner },
    })
  }

  const queryFindDispute = (
    game: number,
    dispute: number
  ): Promise<OneDispute> => {
    const contractAddress = getContractAddress()

    return lcd.wasm.contractQuery(contractAddress, {
      find_dispute: { game: game, dispute: dispute },
    })
  }

  const getContractAddress = () => {
    // @ts-ignore
    return CONTRACT_ADDR
  }

  return {
    queryListDisputes,
    queryMyDisputes,
    queryFindDispute,
    getContractAddress,
  }
}

export default useDisputes
