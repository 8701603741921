import { useLCDClient } from "./lcdClient"
import { CONTRACT_ADDR } from "config/constants"

export const useGames = () => {
  const lcd = useLCDClient()

  type Game = {
    0: {
      key: number
    }
    1: {
      id: number
      category: String
      side_a: String
      side_b: String
      side_c: String
      result: number
      probability_a: number
      probability_b: number
      probability_c: number
      date_time: String
      description: String
      open: boolean
    }
  }

  type ResponseGames = Game[]

  type OneGame = {
    id: number
    category: String
    side_a: String
    side_b: String
    side_c: String
    result: number
    probability_a: number
    probability_b: number
    probability_c: number
    prob_a: number
    prob_b: number
    prob_c: number
    date_time: String
    description: String
  }

  const queryGames = (category: String): Promise<ResponseGames> => {
    const contractAddress = getContractAddress()

    return lcd.wasm.contractQuery(contractAddress, {
      list_games: { category: category },
    })
  }

  const queryGame = (
    category: String | undefined,
    game: String | undefined
  ): Promise<OneGame> => {
    const contractAddress = getContractAddress()

    const gameId = Number(game)

    return lcd.wasm.contractQuery(contractAddress, {
      find_game: {
        category: category,
        game: gameId,
      },
    })
  }

  const getContractAddress = () => {
    // @ts-ignore
    return CONTRACT_ADDR
  }

  return {
    queryGames,
    queryGame,
    getContractAddress,
  }
}

export default useGames
