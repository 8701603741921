import { useTranslation } from "react-i18next"
import { PropsWithChildren, ReactNode } from "react"
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball"
import SportsFootballIcon from "@mui/icons-material/SportsFootball"
import SportsMmaIcon from "@mui/icons-material/SportsMma"
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball"
import SportsHockeyIcon from "@mui/icons-material/SportsHockey"
import SportsTennisIcon from "@mui/icons-material/SportsTennis"
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports"
import StarIcon from "@mui/icons-material/Star"
import styles from "./Category.module.scss"

interface Props extends QueryState {
  id?: String
}

const CategoryIcon = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  let icon: ReactNode

  switch (props.id) {
    case "1": {
      icon = (
        <span>
          <SportsSoccerIcon />
          <span className={styles.name}>{t("Soccer")}</span>
        </span>
      )
      break
    }
    case "2": {
      icon = (
        <span>
          <SportsBasketballIcon />{" "}
          <span className={styles.name}>{t("Basketball")}</span>
        </span>
      )
      break
    }
    case "3": {
      icon = (
        <span>
          <SportsFootballIcon />{" "}
          <span className={styles.name}>{t("Football")}</span>
        </span>
      )
      break
    }
    case "4": {
      icon = (
        <span>
          <SportsMmaIcon />{" "}
          <span className={styles.name}>{t("Boxing&MMA")}</span>
        </span>
      )
      break
    }
    case "5": {
      icon = (
        <span>
          <SportsBaseballIcon />{" "}
          <span className={styles.name}>{t("Baseball")}</span>
        </span>
      )
      break
    }
    case "6": {
      icon = (
        <span>
          <SportsHockeyIcon />{" "}
          <span className={styles.name}>{t("Hockey")}</span>
        </span>
      )
      break
    }
    case "7": {
      icon = (
        <span>
          <SportsTennisIcon />{" "}
          <span className={styles.name}>{t("Tennis")}</span>
        </span>
      )
      break
    }
    case "8": {
      icon = (
        <span>
          <SportsMotorsportsIcon />{" "}
          <span className={styles.name}>{t("Motorsports")}</span>
        </span>
      )
      break
    }
    case "9": {
      icon = (
        <span>
          <StarIcon />{" "}
          <span className={styles.name}>{t("Special Events")}</span>
        </span>
      )
      break
    }
  }

  return <div>{icon}</div>
}

export default CategoryIcon
