import { PropsWithChildren } from "react"

interface Props extends QueryState {
  id: string
}

const YoutubeEmbed = (props: PropsWithChildren<Props>) => {
  const { id } = props

  return (
    <div className="video-responsive">
      <iframe
        width="640"
        height="380"
        src={"https://www.youtube.com/embed/" + id}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

export default YoutubeEmbed
