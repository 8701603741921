import { PropsWithChildren, ReactNode } from "react"
import classNames from "classnames/bind"
import styles from "./Block.module.scss"

const cx = classNames.bind(styles)

interface Props extends QueryState {
  title?: string
  extra?: ReactNode
  mainClassName?: string
  small?: boolean
}

const Block = (props: PropsWithChildren<Props>) => {
  const { children } = props

  return <div className={cx(styles.block)}>{children}</div>
}

export default Block
