import { useLCDClient } from "./lcdClient"
import { CONTRACT_ADDR } from "config/constants"

export const useConfig = () => {
  const lcd = useLCDClient()

  type Config = {
    last_category: String
    last_game: String
    last_dispute: String
    min_bet: String
    max_bet: String
    winner_percent: String
    burn_percent: String
    reserve_percent: String
    builder1_percent: String
    builder2_percent: String
    builder3_percent: String
    builder4_percent: String
    builder5_percent: String
    total_burn: String
    open_matchs: String
    owner: String
    open: String
  }

  const queryConfig = (): Promise<Config> => {
    const contractAddress = getContractAddress()

    return lcd.wasm.contractQuery(contractAddress, { get_config: {} })
  }

  const getContractAddress = () => {
    // @ts-ignore
    return CONTRACT_ADDR
  }

  return {
    queryConfig,
    getContractAddress,
  }
}

export default useConfig
