import { useTranslation } from "react-i18next"
import { useState, useMemo, useEffect } from "react"
import { useDisputes } from "../../data/queries/disputes"
import { useGames } from "../../data/queries/games"
import { useParams } from "react-router"
import { intervalToDuration } from "date-fns"
import { Read } from "components/token"
import { Col, Row, Panel, Flex, Block, BlockValue } from "components/layout"
import { Form, FormItem, LoadingButton } from "components/form"
import { Button, FinderLink } from "components/general"
import { useBlockchain } from "../../data/blockchain"
import { BigNumber } from "bignumber.js"
import { Modal, LoadingCircular } from "components/feedback"
import { useNavigate } from "react-router-dom"
import { useLCDClient } from "../../data/queries/lcdClient"
import { useConnectedWallet } from "@terra-money/wallet-provider"
import { Coin, Fee, MsgExecuteContract } from "@terra-money/terra.js"
import { DEFAULT_GAS_ADJUSTMENT } from "config/constants"
import { CONTRACT_ADDR } from "config/constants"
import { Divider } from "@mui/material"
import { Link } from "react-router-dom"
import Odds from "./components/Odds"
import CategoryIcon from "./components/CategoryIcon"
import useInterval from "utils/hooks/useInterval"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import ImageDispute from "styles/images/dispute/logo.png"
import BackIcon from "@mui/icons-material/ArrowBack"
import styles from "./Dispute.module.scss"

interface Props extends TokenItem {
  decimals: number
  balance: Amount
  gas: any
}

const ClaimForm = ({ token, decimals, balance, gas }: Props) => {
  const { t } = useTranslation()
  const { execute } = useBlockchain()
  const { dispute } = useParams()

  const lcd = useLCDClient()
  const connectedWallet = useConnectedWallet()
  const navigate = useNavigate()

  const { queryFindDispute } = useDisputes()
  const { queryGame } = useGames()

  const [tax, setTax] = useState("0")
  const [txFee, setTxFee] = useState<Fee>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFee, setLoadingFee] = useState<boolean>(false)
  const [btnLabel, setBtnLabel] = useState<string>(t("Receive"))
  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<any>()
  const [cGame, setCgame] = useState<any>({})
  const [cDispute, setCdispute] = useState<any>({})
  const [refound, setRefound] = useState<boolean>(false)
  const [nomatch, setNomatch] = useState<boolean>(false)
  const [winner, setWinner] = useState<boolean>(false)
  const [looser, setLooser] = useState<boolean>(false)

  const time = useTimeText(loading)
  const balanceAfterTx =
    parseFloat(balance) - (parseFloat("1") * 1000000 + parseFloat(tax)) ||
    balance

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section className={styles.details}>
            <aside className={styles.queued}>
              <Flex>{error.message}</Flex>
            </aside>
          </section>
        ),
      }

  const modalLoad = !loading
    ? undefined
    : {
        title: t("Waiting"),
        children: (
          <section className={styles.details}>
            <aside className={styles.queued}>
              <Flex>
                <header>
                  <h2>{time}</h2>
                </header>
              </Flex>
              <Flex>
                <p>{t("Waiting confirmation")}</p>
              </Flex>
            </aside>
          </section>
        ),
      }

  const modalSuccess = !success
    ? undefined
    : {
        title: t("Success"),
        children: (
          <section className={styles.details}>
            <section className={styles.hash}>
              <h1>{t("Tx hash")}</h1>
              <FinderLink tx short>
                {success.txhash}
              </FinderLink>
            </section>
          </section>
        ),
      }

  const calcFee = async () => {
    let amountTotal: number = 1

    setBtnLabel("Estimate fee")

    setTax(
      new BigNumber(amountTotal)
        .times(0.002)
        .times(1000000)
        .decimalPlaces(6)
        .toString()
    )

    if (connectedWallet) {
      setLoadingFee(true)
      const account = await lcd.auth.accountInfo(connectedWallet.walletAddress)
      const signerData = [
        {
          address: connectedWallet?.walletAddress,
          publicKey: account.getPublicKey(),
          sequenceNumber: account.getSequenceNumber(),
        },
      ]

      let msgContract: any = {
        claim: {
          dispute: Number(dispute?.split("-")[1]),
          game: Number(dispute?.split("-")[0]),
        },
      }

      const coins = [
        new Coin(
          "uluna",
          new BigNumber(amountTotal).times(1000000).decimalPlaces(6).toString()
        ),
      ]

      const msgExec = new MsgExecuteContract(
        connectedWallet.walletAddress,
        CONTRACT_ADDR,
        msgContract,
        coins
      )

      const txFeeSimulation = await lcd.tx.estimateFee(signerData, {
        msgs: [msgExec],
        gasPrices: gas,
        feeDenoms: ["uluna"],
        gasAdjustment: DEFAULT_GAS_ADJUSTMENT,
      })

      setTxFee(txFeeSimulation)
      setLoadingFee(false)
      setBtnLabel(t("Receive"))
    }
  }

  useEffect(() => {
    async function fetchData() {
      let arrDispute = dispute?.split("-")
      if (arrDispute !== undefined) {
        await queryFindDispute(
          Number(arrDispute[0]),
          Number(arrDispute[1])
        ).then((data) => {
          queryGame(data.category, String(data.game)).then((dataGame) => {
            let refound = checkRefound(data, dataGame.result)

            if (refound !== undefined) {
              setRefound(refound)
            }

            if (dataGame.result === Number(dispute?.split("-")[2])) {
              setWinner(true)
            } else {
              setLooser(true)
            }

            setCgame(dataGame)
          })

          setNomatch(checkNoMatch(data, Number(dispute?.split("-")[2])))
          setCdispute(data)
        })
      }
      calcFee()
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function checkNoMatch(disp: any, option: number) {
    switch (option) {
      case 1: {
        if (disp.amount_b === 0 && disp.amount_c === 0) {
          return true
        }
        break
      }
      case 2: {
        if (disp.amount_a === 0 && disp.amount_c === 0) {
          return true
        }
        break
      }
      case 3: {
        if (disp.amount_a === 0 && disp.amount_b === 0) {
          return true
        }
        break
      }
    }

    return false
  }

  function checkRefound(disp: any, result: number) {
    switch (result) {
      case 1: {
        if (disp.amount_a === "0") {
          return true
        }
        break
      }
      case 2: {
        if (disp.amount_b === "0") {
          return true
        }
        break
      }
      case 3: {
        if (disp.amount_c === "0") {
          return true
        }
        break
      }
      case 0: {
        return true
      }
    }

    return false
  }

  const handleClaim = async (_event: any) => {
    let msgClaim: any = {
      claim: {
        dispute: Number(cDispute.id),
        game: Number(cGame.id),
      },
    }
    try {
      const result = (await execute(msgClaim, "", txFee, tax)) || {
        txhash: "",
      }
      setSuccess(result)
    } catch (e: any) {
      setError(new Error(t(e)))
    }
  }

  function closeModalSuccess() {
    navigate("/dispute", { replace: true })
  }

  return (
    <Col>
      <Row>
        <Col>
          <Panel>
            <Link to={"/"} className="backButton">
              <BackIcon />
            </Link>
            <br />
            <Row>
              <Col>
                <Block>
                  <Flex>
                    <CategoryIcon id={cGame.category} />
                  </Flex>
                  <Divider />
                  <br />
                  <span className={styles.mutted}>{cGame.description}</span>
                  <br />
                  <span className={styles.mutted}>{cGame.id}</span>
                  {t(String(cGame.side_a))}{" "}
                  {cGame.side_c === "Draw" ? "x" : t(String(cGame.side_c))}{" "}
                  {t(String(cGame.side_b))}
                  <br />
                  <span className={styles.date}>{cGame.date_time}</span>
                </Block>
              </Col>
            </Row>
            <Block>
              <div className={styles.mutted}>
                {t("Dispute")}: {dispute?.split("-")[1]}
                <br />
                {t("You option")}:{" "}
                {dispute?.split("-")[2] === "1" && cGame.side_a}
                {dispute?.split("-")[2] === "2" && cGame.side_b}
                {dispute?.split("-")[2] === "3" && cGame.side_c}
                <br />
                {t("Game result")}:{" "}
                {cGame.result === 0 && "There is no result yet for this game"}
                {cGame.result === 1 && cGame.side_a}
                {cGame.result === 2 && cGame.side_b}
                {cGame.result === 3 && cGame.side_c}
                <br />
                {t(String(cGame.side_a))}: {cDispute.amount_a / 1000000}
                <br />
                {t(String(cGame.side_c))}: {cDispute.amount_c / 1000000}
                <br />
                {t(String(cGame.side_b))}: {cDispute.amount_b / 1000000}
                <br />
                {t("Reward")}:{" "}
                {dispute?.split("-")[2] === "1" && (
                  <Odds color={"success"}>
                    {(Number(cDispute.amount_a) +
                      (Number(cDispute.amount_b) +
                        Number(cDispute.amount_c) * 0.7)) /
                      1000000}{" "}
                    Lunc
                  </Odds>
                )}
                {dispute?.split("-")[2] === "2" && (
                  <Odds color={"success"}>
                    {(Number(cDispute.amount_b) +
                      (Number(cDispute.amount_a) +
                        Number(cDispute.amount_c) * 0.7)) /
                      1000000}{" "}
                    Lunc
                  </Odds>
                )}
                {dispute?.split("-")[2] === "3" && (
                  <Odds color={"success"}>
                    {(Number(cDispute.amount_c) +
                      (Number(cDispute.amount_a) +
                        Number(cDispute.amount_b) * 0.7)) /
                      1000000}{" "}
                    Lunc
                  </Odds>
                )}
                <br />
              </div>
              {cDispute.winner !==
                "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v" && (
                <BlockValue>
                  <Flex>{t("Already withdrawn")}</Flex>
                </BlockValue>
              )}
              {cGame.open === true && (
                <BlockValue>
                  <Flex>{t("Wait result")}</Flex>
                </BlockValue>
              )}
              {winner &&
                cGame.open === false &&
                cDispute.winner ===
                  "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v" &&
                cGame.result !== 0 && (
                  <BlockValue>
                    <Flex>{t("You won")}</Flex>
                  </BlockValue>
                )}
              {looser &&
                cGame.open === false &&
                cDispute.winner ===
                  "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v" &&
                cGame.result !== 0 && (
                  <div>
                    <BlockValue>
                      <Flex>{t("You loose")}</Flex>
                    </BlockValue>
                  </div>
                )}
              {nomatch && cGame.open === false && (
                <BlockValue>
                  <Flex>{t("Dispute no matched")}</Flex>
                </BlockValue>
              )}
              {cGame.result === 0 && cGame.open === false && (
                <BlockValue>
                  <Flex>{t("Game canceled")}</Flex>
                </BlockValue>
              )}
            </Block>
            {winner &&
              cGame.open === false &&
              !refound &&
              cDispute.winner ===
                "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v" && (
                <Form>
                  <FormItem>
                    <LoadingButton
                      submitting={loading || loadingFee}
                      onClick={handleClaim}
                      className={styles.btnBuy}
                    >
                      {t(btnLabel)}
                    </LoadingButton>
                  </FormItem>
                </Form>
              )}
            {refound &&
              cGame.open === false &&
              cDispute.winner ===
                "terra1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v" && (
                <Form>
                  <FormItem>
                    <LoadingButton
                      submitting={loading || loadingFee}
                      onClick={handleClaim}
                      className={styles.btnBuy}
                    >
                      {t("Refound")}
                    </LoadingButton>
                  </FormItem>
                </Form>
              )}
          </Panel>
        </Col>
        <Col>
          <Row>
            <Flex>
              <img src={ImageDispute} width={"80%"} alt="P2P Lunc Dispute" />
            </Flex>
          </Row>
          <Row>
            <Col>
              <Panel>
                <Row>
                  <Col>
                    <Block>{t("Tax")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read amount={tax} token={token} decimals={decimals} />
                    </BlockValue>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Block>{t("Balance")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read amount={balance} token={token} />
                    </BlockValue>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Block>{t("Balance after tx")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read
                        amount={balanceAfterTx}
                        token={token}
                        decimals={decimals}
                      />
                    </BlockValue>
                  </Col>
                </Row>
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
      {modalError && (
        <Modal
          {...modalError}
          icon={<ErrorOutlineIcon fontSize="inherit" className="danger" />}
          onRequestClose={() => setError(undefined)}
          isOpen
        />
      )}
      {modalLoad && (
        <Modal
          {...modalLoad}
          icon={<LoadingCircular />}
          onRequestClose={() => setLoading(false)}
          isOpen
        />
      )}
      {modalSuccess && (
        <Modal
          {...modalSuccess}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button color="primary" onClick={closeModalSuccess} block>
              {t("Confirm")}
            </Button>
          )}
          onRequestClose={() => {
            setSuccess(false)
            navigate("/dispute")
          }}
          isOpen
        />
      )}
    </Col>
  )
}

export default ClaimForm

/* helper */
const useTimeText = (run: boolean) => {
  const start = useMemo(() => new Date(), [])
  const [now, setNow] = useState(new Date())

  useInterval(() => setNow(new Date()), run ? 1000 : null)

  const { minutes, seconds } = intervalToDuration({ start, end: now })
  return [minutes, seconds].map((str) => String(str).padStart(2, "0")).join(":")
}
