import { PropsWithChildren } from "react"
import classNames from "classnames/bind"
import { Color } from "types/components"
import { InlineFlex } from "components/layout"
import styles from "./Odds.module.scss"

const cx = classNames.bind(styles)

interface Props {
  color: Color
  small?: boolean
}

const Odds = ({ color, small, children }: PropsWithChildren<Props>) => {
  const className = classNames(styles.tag, `bg-${color}`, cx({ small }))
  return <InlineFlex className={className}>{children}</InlineFlex>
}

export default Odds

export function calcOdds(prob_a: number, prob_b: number, prob_c: number) {
  let odds: any = []

  // 111
  if (prob_a === 1 && prob_c === 1 && prob_b === 1) {
    odds["a"] = 2.4
    odds["c"] = 2.4
    odds["b"] = 2.4
  }
  // 121
  if (prob_a === 1 && prob_c === 2 && prob_b === 1) {
    odds["a"] = 3.1
    odds["c"] = 1.7
    odds["b"] = 3.1
  }
  // 131
  if (prob_a === 1 && prob_c === 3 && prob_b === 1) {
    odds["a"] = 3.8
    odds["c"] = 1.4
    odds["b"] = 3.8
  }
  // 211
  if (prob_a === 2 && prob_c === 1 && prob_b === 1) {
    odds["a"] = 1.7
    odds["c"] = 3.1
    odds["b"] = 3.1
  }
  // 311
  if (prob_a === 3 && prob_c === 1 && prob_b === 1) {
    odds["a"] = 1.4
    odds["c"] = 3.8
    odds["b"] = 3.8
  }
  // 321
  if (prob_a === 3 && prob_c === 2 && prob_b === 1) {
    odds["a"] = 1.7
    odds["c"] = 3.0
    odds["b"] = 4.5
  }
  // 112
  if (prob_a === 1 && prob_c === 1 && prob_b === 2) {
    odds["a"] = 3.1
    odds["c"] = 3.1
    odds["b"] = 1.7
  }
  // 113
  if (prob_a === 1 && prob_c === 1 && prob_b === 3) {
    odds["a"] = 3.8
    odds["c"] = 3.8
    odds["b"] = 1.4
  }
  // 123
  if (prob_a === 1 && prob_c === 2 && prob_b === 3) {
    odds["a"] = 4.5
    odds["c"] = 3.0
    odds["b"] = 1.7
  }
  // 122
  if (prob_a === 1 && prob_c === 2 && prob_b === 2) {
    odds["a"] = 3.8
    odds["c"] = 2.5
    odds["b"] = 2.5
  }
  // 132
  if (prob_a === 1 && prob_c === 3 && prob_b === 2) {
    odds["a"] = 4.5
    odds["c"] = 1.7
    odds["b"] = 2.4
  }
  // 133
  if (prob_a === 1 && prob_c === 3 && prob_b === 3) {
    odds["a"] = 5.2
    odds["c"] = 1.9
    odds["b"] = 1.9
  }
  // 212
  if (prob_a === 2 && prob_c === 1 && prob_b === 2) {
    odds["a"] = 2.0
    odds["c"] = 3.8
    odds["b"] = 2.0
  }
  // 213
  if (prob_a === 2 && prob_c === 1 && prob_b === 3) {
    odds["a"] = 2.4
    odds["c"] = 4.5
    odds["b"] = 1.7
  }
  // 221
  if (prob_a === 2 && prob_c === 2 && prob_b === 1) {
    odds["a"] = 2.0
    odds["c"] = 2.0
    odds["b"] = 3.8
  }

  // 222
  if (prob_a === 2 && prob_c === 2 && prob_b === 2) {
    odds["a"] = 2.4
    odds["c"] = 2.4
    odds["b"] = 2.4
  }
  // 223
  if (prob_a === 2 && prob_c === 2 && prob_b === 3) {
    odds["a"] = 2.7
    odds["c"] = 2.7
    odds["b"] = 1.9
  }
  // 231
  if (prob_a === 2 && prob_c === 3 && prob_b === 1) {
    odds["a"] = 2.4
    odds["c"] = 1.7
    odds["b"] = 4.5
  }
  // 232
  if (prob_a === 2 && prob_c === 3 && prob_b === 2) {
    odds["a"] = 2.7
    odds["c"] = 1.9
    odds["b"] = 2.7
  }
  // 233
  if (prob_a === 2 && prob_c === 3 && prob_b === 3) {
    odds["a"] = 3.1
    odds["c"] = 2.1
    odds["b"] = 2.1
  }
  // 312
  if (prob_a === 3 && prob_c === 1 && prob_b === 2) {
    odds["a"] = 1.7
    odds["c"] = 4.5
    odds["b"] = 2.4
  }
  // 313
  if (prob_a === 3 && prob_c === 1 && prob_b === 3) {
    odds["a"] = 1.9
    odds["c"] = 5.2
    odds["b"] = 1.9
  }
  // 322
  if (prob_a === 3 && prob_c === 2 && prob_b === 2) {
    odds["a"] = 1.9
    odds["c"] = 2.7
    odds["b"] = 2.7
  }
  // 323
  if (prob_a === 3 && prob_c === 2 && prob_b === 3) {
    odds["a"] = 2.1
    odds["c"] = 3.1
    odds["b"] = 2.1
  }
  // 331
  if (prob_a === 3 && prob_c === 3 && prob_b === 1) {
    odds["a"] = 1.9
    odds["c"] = 1.9
    odds["b"] = 5.2
  }
  // 332
  if (prob_a === 3 && prob_c === 3 && prob_b === 2) {
    odds["a"] = 2.1
    odds["c"] = 2.1
    odds["b"] = 3.1
  }
  // 333
  if (prob_a === 3 && prob_c === 3 && prob_b === 3) {
    odds["a"] = 2.4
    odds["c"] = 2.4
    odds["b"] = 2.4
  }

  return odds
}
