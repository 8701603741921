import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useRoutes } from "react-router-dom"

import { ReactComponent as LotteryIcon } from "styles/images/menu/Lottery.svg"

/* menu */
import Dispute from "pages/dispute/Dispute"

/* auth */
import Auth from "auth/modules/Auth"
import ManageNetworksPage from "auth/networks/ManageNetworksPage"
import AddNetworkPage from "auth/networks/AddNetworkPage"

/* settings */
import Settings from "pages/Settings"

/* dispute */
import DisputePlay from "pages/dispute/Play"
import DisputeClaim from "pages/dispute/Claim"

/* 404 */
import NotFound from "pages/NotFound"

const ICON_SIZE = { width: 20, height: 20 }

export const useNav = () => {
  const { t } = useTranslation()

  const menu = [
    {
      path: "/dispute",
      element: <Dispute />,
      title: t("Dispute"),
      icon: <LotteryIcon {...ICON_SIZE} />,
    },
  ]

  const routes = [
    /* main */
    { path: "/", element: <Dispute /> },

    /* pages */
    ...menu,

    /* auth */
    { path: "/auth/*", element: <Auth /> },
    { path: "/networks", element: <ManageNetworksPage /> },
    { path: "/network/new", element: <AddNetworkPage /> },
    { path: "/settings", element: <Settings /> },

    /* dispute */
    {
      path: "/dispute/play/:category/:game/:opt/:dispute/:prob1/:prob2/:prob3",
      element: <DisputePlay />,
    },
    { path: "/dispute/claim/:dispute", element: <DisputeClaim /> },

    /* 404 */
    { path: "*", element: <NotFound /> },
  ]

  return { menu, element: useRoutes(routes) }
}

/* helpers */
export const useGoBackOnError = ({ error }: QueryState) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (error) navigate("..", { replace: true })
  }, [error, navigate])
}
