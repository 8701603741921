import { PropsWithChildren, ReactNode } from "react"
import { Grid, Flex, Card } from "components/layout"
import styles from "./Category.module.scss"

interface Props extends QueryState {
  id?: string
  label?: string
  extra?: ReactNode
  mainClassName?: string
  small?: boolean
  selected?: boolean
  click?: () => void
}

const Category = (props: PropsWithChildren<Props>) => {
  const { children } = props

  return (
    <Card
      onClick={props.click}
      className={props.selected ? styles.selected : styles.category}
    >
      <Grid>
        <Flex>{children}</Flex>
        <Flex>
          <span>{props.label && <footer>{props.label}</footer>}</span>
        </Flex>
      </Grid>
    </Card>
  )
}

export default Category
