import { useTranslation } from "react-i18next"
import {
  Col,
  Row,
  Page,
  Flex,
  Panel,
  Block,
  BlockValue,
  Table,
} from "components/layout"
import { Modal, LoadingCircular } from "components/feedback"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useAddress } from "data/wallet"
import { useGames } from "../../data/queries/games"
import { useDisputes } from "../../data/queries/disputes"
import { useConfig } from "../../data/queries/config"
import { Card } from "components/layout"
import { Form, FormItem, SearchInput } from "components/form"
import { Divider } from "@mui/material"
import { calcOdds } from "./components/Odds"
import { useSearchParams } from "react-router-dom"
import { Read } from "components/token"

import Odds from "./components/Odds"
import Category from "./components/Category"
import Youtube from "./components/YoutubeEmbed"
import CategoryIcon from "./components/CategoryIcon"
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer"
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball"
import SportsFootballIcon from "@mui/icons-material/SportsFootball"
import SportsMmaIcon from "@mui/icons-material/SportsMma"
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball"
import SportsHockeyIcon from "@mui/icons-material/SportsHockey"
import SportsTennisIcon from "@mui/icons-material/SportsTennis"
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import StarIcon from "@mui/icons-material/Star"
import TwitterIcon from "@mui/icons-material/Twitter"
import TelegramIcon from "@mui/icons-material/Telegram"
import GitHubIcon from "@mui/icons-material/GitHub"
import FacebookIcon from "@mui/icons-material/Facebook"
import styles from "./Dispute.module.scss"
import ImageLogo from "styles/images/dispute/logo.png"
import Button from "components/general/Button"

const Dispute = () => {
  const { t } = useTranslation()
  const { queryGames, queryGame } = useGames()
  const { queryListDisputes, queryMyDisputes } = useDisputes()
  const { queryConfig } = useConfig()
  const [searchParams] = useSearchParams()
  const [windowSize, setWindowSize] = useState(getWindowSize())

  /* form */
  const form = useForm({ mode: "onChange" })
  const { register } = form

  const address = useAddress()
  const navigate = useNavigate()

  const [modal, setModal] = useState<boolean>(false)
  const [config, setConfig] = useState<any>([])
  const [cCategory, setCcategory] = useState<any>("Games")
  const [cIdCategory, setCidCategory] = useState<any>("9")
  const [cDisputes, setCdisputes] = useState<any>([])
  const [myDisputes, setMyDisputes] = useState<any>([])
  const [cGame, setCgame] = useState<any>("No game")
  const [cGames, setCgames] = useState<any>([])
  const [loadingGames, setLoadingGames] = useState<boolean>(false)
  const [loadingDisputes, setLoadingDisputes] = useState<boolean>(false)

  const mobileSize: number = 580

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  function openGame(game: number) {
    setLoadingDisputes(true)
    async function fetchData() {
      await queryGame(cIdCategory, String(game)).then((data) => {
        let odds = calcOdds(
          data.probability_a,
          data.probability_b,
          data.probability_c
        )

        data.prob_a = odds["a"]
        data.prob_b = odds["b"]
        data.prob_c = odds["c"]

        setCgame(data)
        loadDisputes(game, data)
      })
    }
    fetchData()
    setModal(true)
  }

  function openClaim(code: String) {
    navigate("/dispute/claim/" + code)
  }

  function createDispute() {
    navigate(
      "/dispute/play/" +
        cIdCategory +
        "/" +
        cGame.id +
        "/0/0/" +
        cGame.probability_a +
        "/" +
        cGame.probability_b +
        "/" +
        cGame.probability_c,
      {
        replace: true,
      }
    )
  }

  function createMatchOp1(dispute: any) {
    console.log(dispute.id)
    createMatch("1", dispute.id)
  }

  function createMatchOp2(dispute: any) {
    console.log(dispute.id)
    createMatch("2", dispute.id)
  }

  function createMatchOp3(dispute: any) {
    console.log(dispute.id)
    createMatch("3", dispute.id)
  }

  function createMatch(bOpt: String, bIdDispute: String) {
    navigate(
      "/dispute/play/" +
        cIdCategory +
        "/" +
        cGame.id +
        "/" +
        bOpt +
        "/" +
        bIdDispute +
        "/" +
        cGame.probability_a +
        "/" +
        cGame.probability_b +
        "/" +
        cGame.probability_c,
      {
        replace: true,
      }
    )
  }

  useEffect(() => {
    if (searchParams.get("dispute_key")) {
      let disputeCode = String(searchParams.get("dispute_key")).split("-")
      if (disputeCode.length === 5) {
        let p1 = disputeCode[4].substring(0, 1)
        let p2 = disputeCode[4].substring(1, 2)
        let p3 = disputeCode[4].substring(2, 3)
        navigate(
          "/dispute/play/" +
            disputeCode[0] +
            "/" +
            disputeCode[1] +
            "/" +
            disputeCode[2] +
            "/" +
            disputeCode[3] +
            "/" +
            p1 +
            "/" +
            p2 +
            "/" +
            p3,
          {
            replace: true,
          }
        )
      }
    }

    loadGames("12")
    loadConfig()
    if (address !== undefined) {
      loadMyDisputes(address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function loadConfig() {
    async function fetchData() {
      await queryConfig().then((data) => {
        setConfig(data)
      })
    }
    fetchData()
  }

  function loadGames(category: String) {
    setLoadingGames(true)
    async function fetchData() {
      await queryGames(category).then((data) => {
        let listGames: any = []

        data.forEach(function (data, i) {
          if (!data[1].open) {
            data[1].id = 0
          }

          let odds = calcOdds(
            data[1].probability_a,
            data[1].probability_b,
            data[1].probability_c
          )

          data[1].probability_a = odds["a"]
          data[1].probability_b = odds["b"]
          data[1].probability_c = odds["c"]

          let game_date = data[1].date_time.replace(/-/g, "/").replace("T", " ")

          let startGame = new Date(game_date + " GMT").toISOString()
          let now = new Date().toISOString()

          if (data[1].id !== 0) {
            if (startGame > now) {
              // console.log("A: " + startGame)
              // console.log("B: " + now)
              // console.log(data[1])
              listGames.push(data[1])
            }
          }
        })

        setCgames(listGames)
        switch (category) {
          case "1": {
            setCcategory("Soccer")
            setCidCategory("1")
            break
          }
          case "2": {
            setCcategory("Basketball")
            setCidCategory("2")
            break
          }
          case "3": {
            setCcategory("Football")
            setCidCategory("3")
            break
          }
          case "4": {
            setCcategory("Boxing&MMA")
            setCidCategory("4")
            break
          }
          case "5": {
            setCcategory("Baseball")
            setCidCategory("5")
            break
          }
          case "6": {
            setCcategory("Hockey")
            setCidCategory("6")
            break
          }
          case "7": {
            setCcategory("Tennis")
            setCidCategory("7")
            break
          }
          case "8": {
            setCcategory("MotorSport")
            setCidCategory("8")
            break
          }
          case "9": {
            setCcategory("Special Events")
            setCidCategory("9")
            break
          }
        }
        setLoadingGames(false)
      })
    }
    fetchData()
  }

  function loadMyDisputes(owner: String) {
    async function fetchData() {
      await queryMyDisputes(owner).then((data) => {
        let listDisputes: any = []
        data.forEach(function (data, i) {
          if (data[1].side_c === "Draw") {
            data[1].side_c = "x"
          }

          data[1].code =
            String(data[1].game) +
            "-" +
            String(data[1].dispute) +
            "-" +
            String(data[1].option)

          switch (data[1].option) {
            case 1: {
              data[1].side_a = "000" + data[1].side_a
              break
            }
            case 2: {
              data[1].side_b = "000" + data[1].side_b
              break
            }
            case 3: {
              data[1].side_c = "000" + data[1].side_c
              break
            }
          }
          listDisputes.push(data[1])
        })

        listDisputes.sort(function (a: any, b: any) {
          return b.dispute - a.dispute
        })

        setMyDisputes(listDisputes)
      })
    }
    fetchData()
  }

  function loadDisputes(game: number, iGame: any) {
    setLoadingDisputes(true)
    async function fetchData() {
      await queryListDisputes(game).then((data) => {
        let listDisputes: any = []
        let min = 0

        data.forEach(function (data, i) {
          data[1].amount_a /= 1000000
          data[1].amount_b /= 1000000
          data[1].amount_c /= 1000000

          if (data[1].amount_a > 0) {
            switch (iGame.probability_a) {
              case 1: {
                min = data[1].amount_a
                break
              }
              case 2: {
                min = data[1].amount_a / 2
                break
              }
              case 3: {
                min = data[1].amount_a / 3
                break
              }
            }
          }
          if (data[1].amount_b > 0) {
            switch (iGame.probability_b) {
              case 1: {
                min = data[1].amount_b
                break
              }
              case 2: {
                min = data[1].amount_b / 2
                break
              }
              case 3: {
                min = data[1].amount_b / 3
                break
              }
            }
          }
          if (data[1].amount_c > 0) {
            switch (iGame.probability_c) {
              case 1: {
                min = data[1].amount_c
                break
              }
              case 2: {
                min = data[1].amount_c / 2
                break
              }
              case 3: {
                min = data[1].amount_c / 3
                break
              }
            }
          }

          if (data[1].amount_a < 1) {
            switch (iGame.probability_a) {
              case 1: {
                data[1].amount_a = min * -1
                break
              }
              case 2: {
                data[1].amount_a = min * 2 * -1
                break
              }
              case 3: {
                data[1].amount_a = min * 3 * -1
                break
              }
            }
          }
          if (data[1].amount_b < 1) {
            switch (iGame.probability_b) {
              case 1: {
                data[1].amount_b = min * -1
                break
              }
              case 2: {
                data[1].amount_b = min * 2 * -1
                break
              }
              case 3: {
                data[1].amount_b = min * 3 * -1
                break
              }
            }
          }
          if (data[1].amount_c < 1) {
            switch (iGame.probability_c) {
              case 1: {
                data[1].amount_c = min * -1
                break
              }
              case 2: {
                data[1].amount_c = min * 2 * -1
                break
              }
              case 3: {
                data[1].amount_c = min * 3 * -1
                break
              }
            }
          }
          listDisputes.push(data[1])
        })

        setCdisputes(listDisputes)
        setLoadingDisputes(false)
      })
    }
    fetchData()
  }

  return (
    <Page>
      {!!address && (
        <Col>
          <Row>
            <Col>
              <div>
                <Category
                  click={() => {
                    loadGames("1")
                  }}
                  label={t("Soccer")}
                  selected={cCategory === "Soccer" ? true : false}
                >
                  <SportsSoccerIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("2")
                  }}
                  label={t("Basketball")}
                  selected={cCategory === "Basketball" ? true : false}
                >
                  <SportsBasketballIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("3")
                  }}
                  label={t("Football")}
                  selected={cCategory === "Football" ? true : false}
                >
                  <SportsFootballIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("4")
                  }}
                  label={t("Boxing&MMA")}
                  selected={cCategory === "Boxing&MMA" ? true : false}
                >
                  <SportsMmaIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("5")
                  }}
                  label={t("Baseball")}
                  selected={cCategory === "Baseball" ? true : false}
                >
                  <SportsBaseballIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("6")
                  }}
                  label={t("Hockey")}
                  selected={cCategory === "Hockey" ? true : false}
                >
                  <SportsHockeyIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("7")
                  }}
                  label={t("Tennis")}
                  selected={cCategory === "Tennis" ? true : false}
                >
                  <SportsTennisIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("8")
                  }}
                  label={t("MotorSport")}
                  selected={cCategory === "MotorSport" ? true : false}
                >
                  <SportsMotorsportsIcon fontSize={"large"} />
                </Category>
                <Category
                  click={() => {
                    loadGames("9")
                  }}
                  label={t("Special Events")}
                  selected={cCategory === "Special Events" ? true : false}
                >
                  <StarIcon fontSize={"large"} />
                </Category>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              {!loadingGames && (
                <Panel>
                  <div className={styles.title}>{t(cCategory)}</div>
                  {cGames && (
                    <Table
                      dataSource={cGames}
                      pagination={5}
                      columns={[
                        {
                          dataIndex: "date_time",
                          align: "left",
                          hidden:
                            windowSize.innerWidth < mobileSize ? true : false,
                          render: (date_time: String) => (
                            <span className={styles.date}>{date_time} UTC</span>
                          ),
                        },
                        {
                          dataIndex: "side_a",
                          align: "right",
                          render: (side_a: String) => t(String(side_a)),
                        },
                        {
                          dataIndex: "side_c",
                          align: "center",
                          render: (side_c: String) =>
                            side_c === "Draw" ? "x" : t(String(side_c)),
                        },
                        {
                          dataIndex: "side_b",
                          align: "left",
                          render: (side_b: String) => t(String(side_b)),
                        },
                        {
                          dataIndex: "probability_a",
                          align: "center",
                          hidden:
                            windowSize.innerWidth < mobileSize ? true : false,
                          render: (probability_a: number) => (
                            <Odds color={"success"}>
                              {probability_a.toFixed(2)}
                            </Odds>
                          ),
                        },
                        {
                          dataIndex: "probability_c",
                          align: "center",
                          hidden:
                            windowSize.innerWidth < mobileSize ? true : false,
                          render: (probability_c: number) => (
                            <Odds color={"success"}>
                              {probability_c.toFixed(2)}
                            </Odds>
                          ),
                        },
                        {
                          dataIndex: "probability_b",
                          align: "center",
                          hidden:
                            windowSize.innerWidth < mobileSize ? true : false,
                          render: (probability_b: number) => (
                            <Odds color={"success"}>
                              {probability_b.toFixed(2)}
                            </Odds>
                          ),
                        },
                        {
                          dataIndex: "id",
                          align: "center",
                          render: (id: number) => (
                            <div>
                              {id === 0 && (
                                <Button
                                  className={styles.btnEnterGame}
                                  color="default"
                                  size="small"
                                >
                                  <ArrowForwardIosIcon />
                                </Button>
                              )}
                              {id !== 0 && (
                                <Button
                                  className={styles.btnEnterGame}
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    openGame(id)
                                  }}
                                >
                                  <ArrowForwardIosIcon />
                                </Button>
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                  )}
                  {cGames.length === 0 && (
                    <BlockValue>{t("No games")}</BlockValue>
                  )}
                </Panel>
              )}
              {loadingGames && (
                <Panel>
                  <Flex>
                    <LoadingCircular />
                  </Flex>
                </Panel>
              )}
              <Panel>
                <div className={styles.title}>{t("My disputes")}</div>
                <Table
                  dataSource={myDisputes}
                  pagination={5}
                  columns={[
                    {
                      dataIndex: "dispute",
                      align: "left",
                      hidden: windowSize.innerWidth < mobileSize ? true : false,
                      render: (dispute: number) => (
                        <span className={styles.mutted}>#{dispute}</span>
                      ),
                    },
                    {
                      dataIndex: "date_time",
                      align: "left",
                      hidden: windowSize.innerWidth < mobileSize ? true : false,
                      render: (date_time: String) => (
                        <span className={styles.date}>{date_time} UTC</span>
                      ),
                    },
                    {
                      dataIndex: "amount",
                      align: "center",
                      hidden: windowSize.innerWidth < mobileSize ? true : false,
                      render: (amount: String) => (
                        <Odds color={"success"}>
                          {Number(amount) / 1000000}
                          {" LUNC"}
                        </Odds>
                      ),
                    },
                    {
                      dataIndex: "side_a",
                      align: "right",
                      render: (side_a: String) => (
                        <span>
                          {side_a.substring(0, 3) === "000" ? (
                            <Odds color={"default"}>
                              {t(String(side_a.replace("000", "")))}
                            </Odds>
                          ) : (
                            t(String(side_a))
                          )}
                        </span>
                      ),
                    },
                    {
                      dataIndex: "side_c",
                      align: "center",
                      render: (side_c: String) => (
                        <span>
                          {side_c.substring(0, 3) === "000" ? (
                            <Odds color={"default"}>
                              {t(String(side_c.replace("000", "")))}
                            </Odds>
                          ) : (
                            t(String(side_c))
                          )}
                        </span>
                      ),
                    },
                    {
                      dataIndex: "side_b",
                      align: "left",
                      render: (side_b: String) => (
                        <span>
                          {side_b.substring(0, 3) === "000" ? (
                            <Odds color={"default"}>
                              {t(String(side_b.replace("000", "")))}
                            </Odds>
                          ) : (
                            t(String(side_b))
                          )}
                        </span>
                      ),
                    },
                    {
                      dataIndex: "code",
                      align: "center",
                      render: (code: String) => (
                        <div>
                          {code !== "0" && (
                            <Button
                              className={styles.btnEnterGame}
                              color="primary"
                              size="small"
                              onClick={() => {
                                openClaim(code)
                              }}
                            >
                              {t("Claim")}
                            </Button>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              </Panel>
            </Col>
            <Col>
              <img src={ImageLogo} width={"80%"} alt="P2P Lunc Dispute" />
              <Panel>
                <div className={styles.title}>{t("Find dispute")}</div>
                <Form>
                  <FormItem>
                    <SearchInput
                      {...register("dispute_key", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      placeholder={t("Enter Dispute Code x-x-x-xx-xxx")}
                    />
                  </FormItem>
                </Form>
              </Panel>
              <Panel>
                <Row>
                  <Col>
                    <Block>{t("Total Burned")}</Block>
                  </Col>
                  <Col span={2}>
                    <BlockValue>
                      <Read amount={config.total_burn} denom="uluna" />{" "}
                    </BlockValue>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Block>{t("Open Matchs")}</Block>
                  </Col>
                  <Col span={2}>
                    <BlockValue>{config.open_matchs}</BlockValue>
                  </Col>
                </Row>
              </Panel>
              <Row>
                <Col>
                  <BlockValue>
                    <a href="how_to_play_EN_v2.pdf" target={"blank"}>
                      {t("How to Play")}
                    </a>
                  </BlockValue>
                </Col>
                <Col>
                  <BlockValue>
                    <a href="disclaimer_EN_v2.pdf" target={"blank"}>
                      {t("Disclaimer")}
                    </a>
                  </BlockValue>
                </Col>
              </Row>

              <Card>
                <div className={styles.socialIcons}>
                  <a
                    className={styles.socialButton}
                    href="https://twitter.com/LuncP2PDispute"
                    target={"blank"}
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    className={styles.socialButton}
                    href="https://t.me/p2pluncdisputeEN"
                    target={"blank"}
                  >
                    <TelegramIcon />
                  </a>
                  <a
                    className={styles.socialButton}
                    href="https://github.com/lunc-dispute"
                    target={"blank"}
                  >
                    <GitHubIcon />
                  </a>
                  <a
                    className={styles.socialButton}
                    href="https://facebook.com/P2PLuncDispute"
                    target={"blank"}
                  >
                    <FacebookIcon />
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
      {!address && (
        <div>
          <Row>
            <Col span={2}>
              <div className={styles.footer}>
                <Flex>
                  <div className={styles.textNoConnect}>
                    {t(
                      "Wallet not connected, connect your wallet to start playing"
                    )}
                  </div>
                </Flex>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <div className={styles.footer}>
                <Flex>
                  <div>
                    <Youtube id="VzcqI2WiRkQ" />
                  </div>
                </Flex>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Flex>
                <img src={ImageLogo} width={"64%"} alt="P2P Lunc Dispute" />
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col>
              <Flex></Flex>
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <Col span={2}>
          <div className={styles.footer}>
            {t("2023 - P2P Lunc Dispute - all rights reserved")}
            {" | "}
            <b>Version 0.1.37 Beta</b>
          </div>
        </Col>
      </Row>
      {modal && (
        <Modal
          children={
            <div>
              {!loadingDisputes && (
                <Row>
                  <Col>
                    <Block>
                      <Flex>
                        <CategoryIcon id={cIdCategory} />
                      </Flex>
                      <Divider />
                      <div className={styles.date}>{cGame.description}</div>
                      <span className={styles.date}>{cGame.id}</span>
                      {cGame.side_a}{" "}
                      {cGame.side_c === "Draw" ? "x" : t(String(cGame.side_C))}{" "}
                      {cGame.side_b}
                      <br />
                      <span className={styles.date}>{cGame.date_time} UTC</span>
                    </Block>
                  </Col>
                </Row>
              )}
              {!loadingDisputes && (
                <Table
                  dataSource={cDisputes}
                  pagination={3}
                  columns={[
                    {
                      title: "ID",
                      dataIndex: "id",
                      align: "left",
                      render: (id: number) => (
                        <span className={styles.date}>{id}</span>
                      ),
                    },
                    {
                      title: (
                        <div>
                          <Flex>{t(String(cGame.side_a))}</Flex>
                          <Flex>
                            <Odds color={"success"}>{cGame.prob_a}</Odds>
                          </Flex>
                        </div>
                      ),
                      dataIndex: "amount_a",
                      align: "center",
                      render: (amount_a: String, id: any) => (
                        <div>
                          {Number(amount_a) < 1 && (
                            <div>
                              <Button
                                className={styles.btnEmpty}
                                onClick={() => {
                                  createMatchOp1(id)
                                }}
                                size="small"
                                color="primary"
                              >
                                {(Number(amount_a) * -1).toFixed(0)} LUNC
                              </Button>
                              <Flex className={styles.available}>
                                {t("Available")}
                              </Flex>
                            </div>
                          )}
                          {Number(amount_a) > 0 && (
                            <div>
                              <Odds color={"default"}>
                                {Number(amount_a).toFixed(0)}
                                {" LUNC"}
                              </Odds>
                              <Flex className={styles.available}>
                                {t("Already chosen")}
                              </Flex>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      title: (
                        <div>
                          <Flex>{cGame.side_c}</Flex>
                          <Flex>
                            <Odds color={"success"}>{cGame.prob_c}</Odds>
                          </Flex>
                        </div>
                      ),
                      dataIndex: "amount_c",
                      align: "center",
                      render: (amount_c: String, id: any) => (
                        <div>
                          {Number(amount_c) < 1 && (
                            <div>
                              <Button
                                className={styles.btnEmpty}
                                onClick={() => {
                                  createMatchOp3(id)
                                }}
                                size="small"
                                color="primary"
                              >
                                {(Number(amount_c) * -1).toFixed(0)} LUNC
                              </Button>
                              <Flex className={styles.available}>
                                {t("Available")}
                              </Flex>
                            </div>
                          )}
                          {Number(amount_c) > 0 && (
                            <div>
                              <Odds color={"default"}>
                                {Number(amount_c).toFixed(0)}
                                {" LUNC"}
                              </Odds>
                              <Flex className={styles.available}>
                                {t("Already chosen")}
                              </Flex>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      title: (
                        <div>
                          <Flex>{cGame.side_b}</Flex>
                          <Flex>
                            <Odds color={"success"}>{cGame.prob_b}</Odds>
                          </Flex>
                        </div>
                      ),
                      dataIndex: "amount_b",
                      align: "center",
                      render: (amount_b: String, id: any) => (
                        <div>
                          {Number(amount_b) < 1 && (
                            <div>
                              <Button
                                className={styles.btnEmpty}
                                onClick={() => {
                                  createMatchOp2(id)
                                }}
                                size="small"
                                color="primary"
                              >
                                {(Number(amount_b) * -1).toFixed(0)} LUNC
                              </Button>
                              <Flex className={styles.available}>
                                {t("Available")}
                              </Flex>
                            </div>
                          )}
                          {Number(amount_b) > 0 && (
                            <div>
                              <Odds color={"default"}>
                                {Number(amount_b).toFixed(0)}
                                {" LUNC"}
                              </Odds>
                              <Flex className={styles.available}>
                                {t("Already chosen")}
                              </Flex>
                            </div>
                          )}
                        </div>
                      ),
                    },
                  ]}
                />
              )}
              {loadingDisputes && (
                <Flex>
                  <LoadingCircular />
                </Flex>
              )}
              {cDisputes.length === 0 && loadingDisputes === false && (
                <div className={styles.msg}>{t("No disputes")}</div>
              )}
            </div>
          }
          isOpen
          onRequestClose={() => {
            setModal(false)
          }}
          footer={() => (
            <div>
              <div>
                {!loadingDisputes && (
                  <Button
                    color="primary"
                    className={styles.btnBuy}
                    onClick={createDispute}
                    block
                  >
                    {t("New dispute")}
                  </Button>
                )}
              </div>
              <br />
              <div className={styles.helpLinks}>
                <a href="https://youtu.be/XnvcCzmlVxE" target={"blank"}>
                  {t("How to create")}
                </a>
                {" | "}
                <a href="https://youtu.be/JUE-aQYEoRI" target={"blank"}>
                  {t("How to join")}
                </a>
              </div>
            </div>
          )}
        />
      )}
    </Page>
  )
}

export default Dispute
