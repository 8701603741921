import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useState, useMemo, useEffect } from "react"
import { useParams } from "react-router"
import { useGames } from "../../data/queries/games"
import { useDisputes } from "../../data/queries/disputes"
import { useConfig } from "../../data/queries/config"
import { intervalToDuration } from "date-fns"
import { Read } from "components/token"
import { Col, Row, Panel, Flex, Block, BlockValue } from "components/layout"
import { Form, FormItem, Input, LoadingButton } from "components/form"
import { Button, FinderLink } from "components/general"
import { useBlockchain } from "../../data/blockchain"
import { BigNumber } from "bignumber.js"
import { Modal, LoadingCircular } from "components/feedback"
import { calcOdds } from "./components/Odds"
import { useNavigate } from "react-router-dom"
import { useLCDClient } from "../../data/queries/lcdClient"
import { useConnectedWallet } from "@terra-money/wallet-provider"
import { Coin, Fee, MsgExecuteContract } from "@terra-money/terra.js"
import { DEFAULT_GAS_ADJUSTMENT } from "config/constants"
import { CONTRACT_ADDR } from "config/constants"
import { Divider } from "@mui/material"
import { Link } from "react-router-dom"
import CategoryIcon from "./components/CategoryIcon"
import Odds from "./components/Odds"
import useInterval from "utils/hooks/useInterval"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import ImageDispute from "styles/images/dispute/logo.png"
import BackIcon from "@mui/icons-material/ArrowBack"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import CachedIcon from "@mui/icons-material/Cached"
import styles from "./Dispute.module.scss"

interface Props extends TokenItem {
  decimals: number
  balance: Amount
  gas: any
}

const PlayForm = ({ token, decimals, balance, gas }: Props) => {
  const { t } = useTranslation()
  const { execute } = useBlockchain()
  const { category, game, opt, dispute, prob1, prob2, prob3 } = useParams()

  const lcd = useLCDClient()
  const connectedWallet = useConnectedWallet()
  const navigate = useNavigate()

  const { queryGame } = useGames()
  const { queryFindDispute } = useDisputes()
  const { queryConfig } = useConfig()

  /* form */
  const form = useForm({ mode: "onChange" })
  const { register } = form

  const [cGame, setCgame] = useState<any>("No game")
  const [cConfig, setCconfig] = useState<any>("")
  const [amount, setAmount] = useState("0")
  const [maxReward, setMaxReward] = useState<number>(0)
  const [tax, setTax] = useState("0")
  const [txFee, setTxFee] = useState<Fee>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFee, setLoadingFee] = useState<boolean>(false)
  const [btnLabel, setBtnLabel] = useState<string>("Dispute")
  const [error, setError] = useState<Error>()
  const [success, setSuccess] = useState<any>()
  const [option, setOption] = useState<any>(opt)
  const [minDisabled, setMinDisabled] = useState<boolean>(true)
  const [disableA, setDisableA] = useState<boolean>(false)
  const [disableB, setDisableB] = useState<boolean>(false)
  const [disableC, setDisableC] = useState<boolean>(false)

  const time = useTimeText(loading)
  const balanceAfterTx =
    parseFloat(balance) - (parseFloat(amount) * 1000000 + parseFloat(tax)) ||
    balance

  const modalError = !error
    ? undefined
    : {
        title: t("Error"),
        children: (
          <section className={styles.details}>
            <aside className={styles.queued}>
              <Flex>{error.message}</Flex>
            </aside>
          </section>
        ),
      }

  const modalLoad = !loading
    ? undefined
    : {
        title: t("Waiting"),
        children: (
          <section className={styles.details}>
            <aside className={styles.queued}>
              <Flex>
                <header>
                  <h2>{time}</h2>
                </header>
              </Flex>
              <Flex>
                <p>{t("Waiting confirmation")}</p>
              </Flex>
            </aside>
          </section>
        ),
      }

  const modalSuccess = !success
    ? undefined
    : {
        title: t("Success"),
        children: (
          <section className={styles.details}>
            <section className={styles.hash}>
              <h1>{t("Tx hash")}</h1>
              <FinderLink tx short>
                {success.txhash}
              </FinderLink>
            </section>
          </section>
        ),
      }

  const calcFee = async (e: any) => {
    if (option === "0") {
      setError(new Error(t("Select an option to dispute")))
    } else {
      if (balanceAfterTx < 0) {
        setError(new Error(t("Insufficient funds")))
      } else {
        execCalcFee(e.target.form[1].value)
      }
    }
  }

  const execCalcFee = async (amountTotal: number) => {
    if (amountTotal > 0) {
      setBtnLabel(t("Estimate fee"))
    }

    setTax(
      new BigNumber(amountTotal)
        .times(0.002)
        .times(1000000)
        .decimalPlaces(6)
        .toString()
    )

    if (connectedWallet && amountTotal > 0) {
      setLoadingFee(true)
      const account = await lcd.auth.accountInfo(connectedWallet.walletAddress)
      const signerData = [
        {
          address: connectedWallet?.walletAddress,
          publicKey: account.getPublicKey(),
          sequenceNumber: account.getSequenceNumber(),
        },
      ]

      let msgContract

      dispute === "0"
        ? (msgContract = {
            create_dispute: {
              game: Number(game),
              category: String(cGame.category),
              opt: Number(option),
            },
          })
        : (msgContract = {
            match: {
              dispute: Number(dispute),
              game: Number(game),
              opt: Number(option),
            },
          })

      const coins = [
        new Coin(
          "uluna",
          new BigNumber(amountTotal).times(1000000).decimalPlaces(6).toString()
        ),
      ]

      const msgExec = new MsgExecuteContract(
        connectedWallet.walletAddress,
        CONTRACT_ADDR,
        msgContract,
        coins
      )

      const txFeeSimulation = await lcd.tx.estimateFee(signerData, {
        msgs: [msgExec],
        gasPrices: gas,
        feeDenoms: ["uluna"],
        gasAdjustment: DEFAULT_GAS_ADJUSTMENT,
      })

      setTxFee(txFeeSimulation)
      setLoadingFee(false)
      setBtnLabel("Dispute")
    }
  }

  useEffect(() => {
    async function fetchData() {
      await queryGame(category, game).then((data) => {
        let odds = calcOdds(
          data.probability_a,
          data.probability_b,
          data.probability_c
        )
        data.prob_a = odds["a"]
        data.prob_b = odds["b"]
        data.prob_c = odds["c"]
        setCgame(data)
      })
      await queryConfig().then((data) => {
        setCconfig(data)
      })
      await queryFindDispute(Number(game), Number(dispute)).then((data) => {
        let min = 0
        let tAmount = 0

        if (data.amount_a > 0) {
          min = data.amount_a / Number(prob1)
          setDisableA(true)
        }

        if (data.amount_b > 0) {
          min = data.amount_b / Number(prob2)
          setDisableB(true)
        }

        if (data.amount_c > 0) {
          min = data.amount_c / Number(prob3)
          setDisableC(true)
        }

        switch (opt) {
          case "1": {
            tAmount = (min * Number(prob1)) / 1000000
            break
          }
          case "2": {
            tAmount = (min * Number(prob2)) / 1000000
            break
          }
          case "3": {
            tAmount = (min * Number(prob3)) / 1000000
            break
          }
        }

        min = min / 1000000

        execCalcAmount(min)
        execCalcFee(tAmount)
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBuy = async (_event: any) => {
    const amount = _event.target.form[dispute === "0" ? 1 : 0].value
    const min_padronize = cConfig.min_bet / 1000000
    const max_padronize = cConfig.max_bet / 1000000

    if (option === "0") {
      setError(new Error(t("Select an option to dispute")))
    } else {
      if (amount === "" || amount < min_padronize || amount > max_padronize) {
        console.log(amount)
        setError(
          new Error(
            t("Invalid dispute amount") +
              ", min: " +
              min_padronize +
              " max: " +
              max_padronize
          )
        )
      } else {
        if (balanceAfterTx < 0) {
          setError(new Error(t("Insufficient funds")))
        } else {
          let msgContract: any

          dispute === "0"
            ? (msgContract = {
                create_dispute: {
                  game: Number(game),
                  category: String(cGame.category),
                  opt: Number(option),
                },
              })
            : (msgContract = {
                match: {
                  dispute: Number(dispute),
                  game: Number(game),
                  opt: Number(option),
                },
              })

          setLoading(true)
          try {
            const coins = parseInt(amount).toString()
            const result = (await execute(msgContract, coins, txFee, tax)) || {
              txhash: "",
            }
            setSuccess(result)
          } catch (e: any) {
            setError(new Error(t(e)))
          }
          setLoading(false)
        }
      }
    }
  }

  function closeModalSuccess() {
    navigate("/dispute", { replace: true })
  }

  const calcAmount = async (e: any) => {
    e.target.value = Math.trunc(e.target.value)
    execCalcAmount(e.target.value)
  }

  function execCalcAmount(min: number) {
    let value = 0

    switch (option) {
      case "1": {
        value = min * Number(prob1)
        break
      }
      case "2": {
        value = min * Number(prob2)
        break
      }
      case "3": {
        value = min * Number(prob3)
        break
      }
    }

    let total =
      Number(prob1) * Number(min) +
      Number(prob2) * Number(min) +
      Number(prob3) * Number(min)

    setMaxReward((total - value) * 0.7 + value)

    setAmount(
      new BigNumber(value)
        .integerValue(BigNumber.ROUND_CEIL)
        .decimalPlaces(6)
        .toString()
    )
  }

  const selectOption = (
    event: React.MouseEvent<HTMLElement>,
    option: string | null
  ) => {
    setOption(option)
    setMinDisabled(false)
  }

  function clearOption() {
    setOption("0")
    setMinDisabled(true)
  }

  return (
    <Col>
      <Row>
        <Col>
          <Panel>
            <Link to={"/"} className="backButton">
              <BackIcon />
            </Link>
            <br />
            <Row>
              <Col>
                <Block>
                  <Flex>
                    <CategoryIcon id={cGame.category} />
                  </Flex>
                  <Divider />
                  <div className={styles.mutted}>{cGame.description}</div>
                  <span className={styles.mutted}>{cGame.id}</span>
                  {t(String(cGame.side_a))}{" "}
                  {cGame.side_c === "Draw" ? "x" : t(String(cGame.side_c))}{" "}
                  {t(String(cGame.side_b))}
                  <br />
                  <span className={styles.mutted}>{cGame.date_time} UTC</span>
                  <br />
                  <span className={styles.mutted}>
                    {t("Dispute Code")}: {category}
                    {"-"}
                    {game}
                    {"-"}
                    {opt}
                    {"-"}
                    {dispute}
                    {"-"}
                    {prob1}
                    {prob2}
                    {prob3}
                  </span>
                  <br />
                  <Odds color={"success"}>{cGame.prob_a}</Odds>
                  <Odds color={"success"}>{cGame.prob_b}</Odds>
                  <Odds color={"success"}>{cGame.prob_c}</Odds>
                </Block>
              </Col>
            </Row>
            <Block>
              <div className={styles.formBuy}>
                <BlockValue>
                  {option !== "0" && (
                    <div className={styles.disputeFor}>
                      {t("Dispute for ")}
                      {option === "1" ? t(String(cGame.side_a)) : ""}
                      {option === "2" ? t(String(cGame.side_b)) : ""}
                      {option === "3" ? t(String(cGame.side_c)) : ""}{" "}
                      {option === "1" ? (
                        <span>
                          <Odds color={"success"}>{cGame.prob_a}</Odds>
                        </span>
                      ) : (
                        ""
                      )}
                      {option === "2" ? (
                        <span>
                          <Odds color={"success"}>{cGame.prob_b}</Odds>
                        </span>
                      ) : (
                        ""
                      )}
                      {option === "3" ? (
                        <span>
                          <Odds color={"success"}>{cGame.prob_c}</Odds>
                        </span>
                      ) : (
                        ""
                      )}
                      <div className={styles.renew} onClick={clearOption}>
                        <span>
                          <CachedIcon />
                        </span>
                      </div>
                    </div>
                  )}

                  {option === "0" && (
                    <div>
                      <ToggleButtonGroup
                        exclusive
                        onChange={selectOption}
                        className={styles.mainToggle}
                      >
                        <ToggleButton
                          disabled={disableA}
                          value="1"
                          className={styles.toggle}
                        >
                          {t(String(cGame.side_a))}
                        </ToggleButton>
                        <ToggleButton
                          disabled={disableC}
                          value="3"
                          className={styles.toggle}
                        >
                          {t(String(cGame.side_c))}
                        </ToggleButton>
                        <ToggleButton
                          disabled={disableB}
                          value="2"
                          className={styles.toggle}
                        >
                          {t(String(cGame.side_b))}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  )}
                </BlockValue>
                <Odds color={"success"}>
                  {t("Max rewards") + ": " + maxReward.toFixed(2) + " LUNC"}
                </Odds>
                <br />
                <br />
                <Form>
                  {dispute === "0" && (
                    <FormItem label={`${t("Minimum Amount")}`}>
                      <Input
                        {...register("amount", {
                          valueAsNumber: true,
                          required: true,
                        })}
                        type="number"
                        token="uluna"
                        onChange={calcAmount}
                        onBlur={calcFee}
                        inputMode="decimal"
                        disabled={minDisabled}
                        readOnly={minDisabled}
                      />
                    </FormItem>
                  )}

                  <FormItem label={`${t("Your Dispute Amount")}`}>
                    <Input
                      {...register("total", {
                        validate: {},
                      })}
                      token="uluna"
                      inputMode="decimal"
                      disabled={true}
                      readOnly={true}
                      value={amount}
                    />
                  </FormItem>

                  <FormItem>
                    <LoadingButton
                      submitting={loading || loadingFee}
                      onClick={handleBuy}
                      className={styles.btnBuy}
                    >
                      {t(btnLabel)}
                    </LoadingButton>
                  </FormItem>
                </Form>
              </div>
            </Block>
          </Panel>
        </Col>
        <Col>
          <Row>
            <Flex>
              <img src={ImageDispute} width={"80%"} alt="P2P Lunc Dispute" />
            </Flex>
          </Row>
          <Row>
            <Col>
              <Panel>
                <Row>
                  <Col>
                    <Block>{t("Tax")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read amount={tax} token={token} decimals={decimals} />
                    </BlockValue>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Block>{t("Balance")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read amount={balance} token={token} />
                    </BlockValue>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Block>{t("Balance after tx")}</Block>
                  </Col>
                  <Col>
                    <BlockValue>
                      <Read
                        amount={balanceAfterTx}
                        token={token}
                        decimals={decimals}
                      />
                    </BlockValue>
                  </Col>
                </Row>
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
      {modalError && (
        <Modal
          {...modalError}
          icon={<ErrorOutlineIcon fontSize="inherit" className="danger" />}
          onRequestClose={() => setError(undefined)}
          isOpen
        />
      )}
      {modalLoad && (
        <Modal
          {...modalLoad}
          icon={<LoadingCircular />}
          onRequestClose={() => setLoading(false)}
          isOpen
        />
      )}
      {modalSuccess && (
        <Modal
          {...modalSuccess}
          icon={<DoneAllIcon fontSize="inherit" className="success" />}
          footer={() => (
            <Button color="primary" onClick={closeModalSuccess} block>
              {t("Confirm")}
            </Button>
          )}
          onRequestClose={() => {
            setSuccess(false)
            navigate("/dispute")
          }}
          isOpen
        />
      )}
    </Col>
  )
}

export default PlayForm

/* helper */
const useTimeText = (run: boolean) => {
  const start = useMemo(() => new Date(), [])
  const [now, setNow] = useState(new Date())

  useInterval(() => setNow(new Date()), run ? 1000 : null)

  const { minutes, seconds } = intervalToDuration({ start, end: now })
  return [minutes, seconds].map((str) => String(str).padStart(2, "0")).join(":")
}
