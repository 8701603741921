import {
  Coin,
  ExtensionOptions,
  Fee,
  MsgExecuteContract,
} from "@terra-money/terra.js"
import { useConnectedWallet } from "@terra-money/wallet-provider"
import { Execute } from "./models/Execute"
import { CONTRACT_ADDR } from "config/constants"

export const useBlockchain = () => {
  const connectedWallet = useConnectedWallet()

  const execute = async (
    execute: Execute,
    amount?: string,
    fee?: Fee,
    tax?: string
  ) => {
    const contractAddress = getContractAddress()
    const coins =
      amount && amount !== "0"
        ? [new Coin("uluna", (Number(amount) * 10 ** 6).toString())]
        : []

    if (connectedWallet) {
      const msgExec = new MsgExecuteContract(
        connectedWallet.walletAddress,
        contractAddress,
        execute,
        coins
      )

      let txData: ExtensionOptions = {
        msgs: [msgExec],
        isClassic: true,
      }

      if (fee && tax) {
        txData.fee = new Fee(
          fee.gas_limit,
          fee.amount.add(new Coin("uluna", tax))
        )
      }

      const res = await connectedWallet.post(txData)
      return res.result
    } else Promise.reject("Wallet is not connected")
  }

  const getConnectedWalletAddress = () => {
    return connectedWallet?.walletAddress
  }

  const getContractAddress = () => {
    // @ts-ignore
    return CONTRACT_ADDR
  }

  return {
    execute,
    getContractAddress,
    getConnectedWalletAddress,
  }
}

export default useBlockchain
