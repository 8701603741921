import { getErrorMessage } from "utils/error"
import Layout, { Page } from "components/layout"
import { Content, Header, Actions } from "components/layout"
import { ErrorBoundary, Wrong } from "components/feedback"

/* routes */
import { useNav } from "./routes"

/* header */
import Refresh from "./sections/Refresh"
import Preferences from "./sections/Preferences"
import ConnectWallet from "./sections/ConnectWallet"

/* extra */
import DevTools from "./sections/DevTools"

/* init */
import InitBankBalance from "./InitBankBalance"

const App = () => {
  const { element: routes } = useNav()

  return (
    <Layout>
      <Header>
        <div></div>
        <Actions>
          <DevTools />
          <section>
            <Refresh />
            <Preferences />
          </section>
          <ConnectWallet />
        </Actions>
      </Header>

      <Content>
        <ErrorBoundary fallback={fallback}>
          <InitBankBalance>{routes}</InitBankBalance>
        </ErrorBoundary>
      </Content>
    </Layout>
  )
}

export default App

/* error */
export const fallback = (error: Error) => (
  <Page>
    <Wrong>{getErrorMessage(error)}</Wrong>
  </Page>
)
